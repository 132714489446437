import React from "react"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { graphql } from "gatsby"
import Carousel from 'react-grid-carousel'

export default function GamingProjectTemplate({ data }) {

    let {title, field_years, body} = data.nodeGamingIndustryWork;
    let {field_role_s_, field_event_type} = data.nodeGamingIndustryWork.relationships;
    let {field_work_images} = data.nodeGamingIndustryWork.relationships;
    const CMS_BASE_URL = "https://cms.krestonshirley.com";
    //const [youtubeObj, setYoutubeURL] = React.useState({name: '', url: ''});


    //let youtubeURL, youtubeName;
    //console.log(youtubeObj);
    return (
        <Layout>
            <SEO title={title}/>
            <div className="container">
                <div className="row">
                    <h1 className="text-center">{title}</h1>
                    <div className="col-sm-12">
                     {/*   Overview */}
                     {/*My Roles*/}
                    <div dangerouslySetInnerHTML={{ __html: body.value }}/>
                        <div className="row">
                            <div className="col-sm-6">
                                {/*    Event Type */}
                                <h3><span>Roles:</span>{field_role_s_.name}</h3>
                                <ul>{field_role_s_.map( (role) => (
                                    <li>{role.name}</li>
                                ))}</ul>

                            </div>
                            <div className="col-sm-6">
                                {/*  Years  */}
                                <h3><span>Program Type(s):</span></h3>
                                <ul>{field_event_type.map((event) => (<li>{event.name}</li>))}</ul>
                                <h3><span>Years Active:</span> {field_years}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Carousel cols={3} rows={5} gap={10}>
                        {
                            field_work_images.map((image, i) => {
                                console.log("Whats images", image)
                                if(image["__typename"] !== "media__remote_video"){
                                    console.log("Whats image", image)
                                    let {field_media_image: {uri: {url: imageURL}}} = image.relationships

                                    //console.log("URL",imageURL)
                                    return (
                                        <Carousel.Item key={i}>
                                            <img width="100%" src={CMS_BASE_URL + imageURL} />
                                        </Carousel.Item>
                                    );
                                }
                                else{
                                    //console.log(image.field_media_oembed_video);
                                    // youtubeURL = extractVideoID(image.field_media_oembed_video);
                                    //
                                    // youtubeName = image.name;


                                    //console.log(youtubeURL);

                                    //setYoutubeURL({name: image.name , url: extractVideoID(image.field_media_oembed_video)})
                                    // return <></>
                                }
                            })
                        }
                    </Carousel>
                </div>
            </div>
        </Layout>
    )

    function extractVideoID(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[7].length == 11) {
            return match[7];
        } else {
            //alert("Could not extract video ID.");
        }
    }
}

export const pageQuery = graphql`
  query($path: String!) {
    nodeGamingIndustryWork(path: {alias: {eq: $path}}) {
      title
      body {
        value
      }
      field_years
      relationships {
        field_role_s_ {
          name
        }
        field_event_type {
          name
        }
        field_work_images {
          ... on media__image {
            relationships {
              field_media_image {
                uri {
                  url
                }
              }
            }
          }
          ... on media__remote_video {
            name
            field_media_oembed_video
          }
        }
      }
    }
  }
`


